import React from 'react';
import '../../assets/styles/home.scss';
import Skeleton from 'react-skeleton-loader';
import useWindowDimensions from '../../custom_hooks/useWindowDimensions';
import { hex_to_rgba } from '../../custom_functions/hex_to_rgba';

const LoadingPageView = () => {
    const { width } = useWindowDimensions();
    const URL_PARAMS = new URLSearchParams(window.location.search);
    if (URL_PARAMS.get('share_date_time') !== 'true') {
        return(
            <div className="home-timesync loading">
                <div className="container">
                    <div className="left" style={{backgroundColor:'#F6F5F8', boxShadow: `inset -1.063rem 0 1.25rem 0 ${hex_to_rgba('#F6F5F8', 0.05)}`}}>
                        <div className="section-top">
                            <div className="name-picture booking-skeleton">
                                <div className="photo">
                                    <Skeleton width={`${width >= 858 ? '5' : '2' }rem`} height={`${width >= 858 ? '5' : '2' }rem`} color={"#dedede"} borderRadius={`${width >= 858 ? '.625' : '.5' }rem`} widthRandomness={0} heightRandomness={0} />
                                </div>
                            </div>                    
                        </div>
                    </div>
                    <div className="right-content"></div>
                </div>
            </div>
        )
    }
    return null;
};

export default LoadingPageView;