import React from 'react';
import loadable from '@loadable/component';
import ReactDOM from 'react-dom';
import './index.scss';
import * as serviceWorker from './serviceWorker';
import Loadable from './components/loading/Loadable';

// internationalization
import './config/i18n';

const renderLoader = () => {
  return <Loadable />;
};

const App = loadable(() => import('./App'), {
  fallback: renderLoader(),
});

ReactDOM.render(<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
